import { Switch, Route } from 'react-router-dom';

import Homepage from 'pages/homepage';
import ProjectDetails from 'pages/project-details';
import ProjectDonationStep1 from 'pages/project-donation/step1';
import ProjectDonationStep2 from 'pages/project-donation/step2';
import ProjectDonationStep3 from 'pages/project-donation/step3';
import ProjectDonationFeedback from 'pages/project-donation/feedback';
import ProjectDonationCancel from 'pages/project-donation/cancel';
import ProjectDonationSuccess from 'pages/project-donation/success';
import Auth from 'pages/auth';
import Profile from 'pages/profile';
import UserProfile from 'pages/user-profile';
import FaqPage from 'pages/static-pages/faq';
import RegComplete from 'pages/reg-complete';
import ResetPassword from 'pages/reset-password';
import StaticPages from 'pages/static-pages';
import Page404 from 'pages/page-404';

import { UI_ROUTES } from 'constants/routes';

const Routes = () => (
  <Switch>
    <Route exact path='/' component={Homepage} />
    <Route exact path='/project/:alias' component={ProjectDetails} />
    <Route exact path='/project/:alias/donation/step1' component={ProjectDonationStep1} />
    <Route exact path='/project/:alias/donation/step2' component={ProjectDonationStep2} />
    <Route exact path='/project/:alias/donation/step3' component={ProjectDonationStep3} />
    <Route exact path='/project/:alias/payment-feedback/:donationId' component={ProjectDonationFeedback} />
    <Route exact path='/project/:alias/donation/cancel' component={ProjectDonationCancel} />
    <Route exact path='/project/:alias/donation/success' component={ProjectDonationSuccess} />
    <Route exact path='/auth' component={Auth} />
    <Route exact path='/profile' component={Profile} />
    <Route exact path='/profile/:userId' component={UserProfile} />
    <Route exact path='/faq' component={FaqPage} />
    <Route exact path={UI_ROUTES.complete} component={RegComplete} />
    <Route exact path={`${UI_ROUTES.complete}/:key`} component={RegComplete} />
    <Route exact path={`${UI_ROUTES.resetpassword}/:key`} component={ResetPassword} />
    <Route exact path={UI_ROUTES.resetpassword} component={ResetPassword} />
    <Route
      exact
      path={[
        UI_ROUTES.ibsh,
        UI_ROUTES.datenschutz,
        UI_ROUTES.impressum,
        UI_ROUTES.nutzungsbedingungen,
        UI_ROUTES.projektbedingungen,
        UI_ROUTES.presse,
        UI_ROUTES.soGehts,
        UI_ROUTES.darumGehts,
        UI_ROUTES.partner
      ]}
      component={StaticPages}
    />
    <Route path='*' component={Page404} />
  </Switch>
);

export default Routes;
