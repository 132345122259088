import { useState, useEffect } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { selectIsLoggedIn, selectUser } from 'redux/auth/selectors';
import { selectProject } from 'redux/project/selectors';
import { selectLoading } from 'redux/donation/selectors';
import { donationActions } from 'redux/donation/actions';

import ProjectHeader from '../../project-details/project-header';
import Stats from '../../project-details/stats';
import { isSpecialProject } from 'utils/helpers';

import * as S from './styles';

interface DonationParams {
  alias: string;
  donationId: string;
}

type Props = {};

const ProjectDonationFeedback = (props: Props) => {
  const { alias } = useParams<DonationParams>();
  const { donationId } = useParams<DonationParams>();

  const project = useSelector(selectProject);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const user = useSelector(selectUser);
  const { status } = useSelector(selectLoading);

  const dispatch = useDispatch();

  const [payload, setPayload] = useState({});

  useEffect(() => {
    const initValues = {
      project_donation_id: donationId,
      text: '',
      user_id: `${user?.uid}`
    };

    setPayload(initValues);
  }, [user, donationId]);

  const changeInputHandler = (event) => {
    setPayload({ ...payload, [event.target.name]: event.target.value });
  }

  const onSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const formData = new FormData();

    Object.keys(payload).forEach(key => {
      formData.append(key, payload[key]);
    })

    dispatch(donationActions.paymentComment({ formData, alias }));
  };

  const renderForm = () => (
    <>
      <Row className='mt-4'>
        <Col>
          <p className='h5'>
            <strong>Möchten Sie einen Kommentar zu Ihrer Unterstützung hinterlassen?</strong>
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          Ihre Meinung ist gefragt. Lassen Sie andere wissen, was Sie an dem Projekt fasziniert.
          Warum Sie dafür spenden. Ihr Kommentar erscheint auf der Projektseite.
        </Col>
      </Row>
      <Form onSubmit={onSubmit} className='mt-1'>
        <Row>
          <Col sm={2}>
            <S.Avatar src={ user?.avatar || '/person.svg' } roundedCircle  />
          </Col>
          <Col>
            <Form.Group className='mb-3'>
              <Form.Control
                name='text'
                as='textarea'
                rows={3}
                onChange={changeInputHandler}
              />
            </Form.Group>
          </Col>
        </Row>
        <S.SubmitButton variant='primary' type='submit' disabled={status}>
          Kommentar abschicken
        </S.SubmitButton>
      </Form>
    </>
  )

  return (
    <>
      <ProjectHeader />
      <Stats />

      <S.StyledContainer fluid>
        <S.DefaultContainer fluid='lg'>
          <Row>
            <Col sm={12} md={4} className='text-center text-sm-left'>
              <S.StyledImage src={ project?.user?.avatar || '/person.svg' } roundedCircle  />
            </Col>
            <Col sm={12} md={8}>
              <Row>
                <Col>
                  <p className='h5'>
                    <strong>Vielen Dank für Ihre Unterstützung!</strong>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  Vielen Dank für Ihre Spendenzusage! Wir freuen uns sehr,
                  dass Sie unser Projekt „{project?.title}“ unterstützen!
                </Col>
              </Row>
              <br/>
              {isSpecialProject(alias) && <Row>
                <Col>
                  Hier haben Sie die Möglichkeit Ihre persönliche Patenschaftsurkunde
                  herunterzuladen: <a href='/Baumurkunde.pdf' target='blank'>Patenschaftsurkunde</a>
                </Col>
              </Row>}
            </Col>
          </Row>
          { isLoggedIn && renderForm() }
          <Row className='mt-4'>
            <Col>
              <p className='h5'>
                <strong>Teilen Sie das Projekt mit Ihren Verwandten, Freunden und Bekannten.</strong>
              </p>
            </Col>
          </Row>
          <Row className='mt-4'>
            <Col>
              <Link to={`/project/${alias}?tab=supporters`}>
                <Button variant='primary' type='submit'>
                  Zu den anderen Unterstützungen
                </Button>
              </Link>
            </Col>
          </Row>
        </S.DefaultContainer>
      </S.StyledContainer>
    </>
  );
};

export default ProjectDonationFeedback;
