import { getServerByHost, isProduction } from '../config';
import { useLocation } from 'react-router-dom';

import { ERRORS } from 'constants/errors';

const MS_PER_DAY = 1000 * 60 * 60 * 24;

export const convertToEuro = (num: any) => {
  let value = 0;

  if (typeof num === 'number')
    value = num

  if (typeof num === 'string')
    value = parseInt(num, 10)

  const currency = Math.round(value).toLocaleString('de-DE', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0
  });

  return currency;
};

export const dateDiffInDays = (a: any, b: any) => {
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / MS_PER_DAY);
}

export const useProxy = (url: string) => {
  if (isProduction()) return url
  const hostname = window.location.hostname;
  const newUrl = url.replace(getServerByHost(hostname).API_URL.url, '/api-proxy')

  return newUrl;
};

export const useQueryParam = () => (
  new URLSearchParams(useLocation().search)
)

export const checkError = (errors: string[] | undefined, type: string) => {
  if (!errors) return null

  const changedErrors = errors.map((error) => error === ERRORS[type].eng ? ERRORS[type].ger : error)

  return changedErrors;
}

export const isSpecialProject = (alias: string) => (alias === 'baum-fuer-baum-fuer-sh');
