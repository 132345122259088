import { Container, Row, Col } from 'react-bootstrap';
import { IoMdMegaphone } from 'react-icons/io';

import { UI_ROUTES } from '../../constants/routes';

import * as S from './styles';

const AppFooter = () => {
  return (
    <S.FooterWrapper>
      <Container fluid='lg' className='hidden-print'>
        <Row>
          <Col md={6}>
            <S.FooterHeader>
              Warum &bdquo;WIR BEWALDEN.SH&ldquo;?
            </S.FooterHeader>
            <S.FooterText>
              Die Spendenplattform der IB.SH bringt frische Ideen, engagierte Projektstarter und mögliche Spender zusammen. So bewalden wir Schleswig-Holstein.
            </S.FooterText>
            <a href='http://www.ib-sh.de' rel='noreferrer'>
              <S.FooterLogo alt='IB.SH' src='/logo-footer.png' />
            </a>
          </Col>
          <Col md={6}>
            <Row>
              <Col md={12}>
                <S.FooterHeader>
                  <S.IconMegaphone><IoMdMegaphone /></S.IconMegaphone>
                  Soziale Netzwerke bewegen
                </S.FooterHeader>
              </Col>
            </Row>
            <Row style={{marginTop: '-5px'}}>
              <Col md={8}>
                <S.FooterText>
                  Folgen Sie uns auf Facebook: Aktuelle Projekte aus Schleswig-Holstein und nützliche Infos rund ums Spenden.
                </S.FooterText>
              </Col>
              <Col md={4}>
                <a href='https://www.facebook.com/profile.php?id=61557450793510' target='_blank'>
                  <S.FacebookLogo alt='WIR BEWALDEN.SH auf Facebook öffnen' src='/facebook.svg' />
                </a>
              </Col>
            </Row>
            <Row>
              <Col sm={4}>
                <S.FooterHeader>
                  Hilfe
                </S.FooterHeader>
                <S.FooterNav>
                  <li>
                    <a href={UI_ROUTES.soGehts}>
                      So geht’s
                    </a>
                  </li>
                  <li>
                    <a href='/faq'>
                      FAQ
                    </a>
                  </li>
                </S.FooterNav>
              </Col>
              <Col sm={4}>
                <S.FooterHeader>
                  Infos
                </S.FooterHeader>
                <S.FooterNav>
                  <li>
                    <a href={UI_ROUTES.nutzungsbedingungen}>
                      Nutzungsbedingungen
                    </a>
                  </li>
                  <li>
                    <a href={UI_ROUTES.projektbedingungen}>
                      Projektbedingungen
                    </a>
                  </li>
                  <li>
                    <a href={UI_ROUTES.datenschutz}>
                      Datenschutz
                    </a>
                  </li>
                  <li>
                    <a href={UI_ROUTES.impressum}>
                      Impressum
                    </a>
                  </li>
                </S.FooterNav>
              </Col>
              <Col sm={4}>
                <S.FooterHeader>
                  Über uns
                </S.FooterHeader>
                <S.FooterNav>
                  <li>
                    <a href={UI_ROUTES.ibsh}>
                      Die IB.SH
                    </a>
                  </li>
                  <li>
                    <a href={UI_ROUTES.presse}>
                      Presse
                    </a>
                  </li>
                </S.FooterNav>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </S.FooterWrapper>
  )
}

export default AppFooter;
